<template>
  <div class="mx-wrap__header">
    <div>
      <div class="mx-header-btn">
        <button :class="{'mx-btn-month-active': btnMontActive(btn), 'mx-btn-month-current': btnMontCurrent(btn)}" class="mx-btn-month" v-for="btn in btn_months" :key="btn" @click="setMxMonth(btn)">{{ $t(`mx_calender.month_num.${btn}`) }}</button>
      </div>
    </div>
    <div class="mx-wrap ">
      <div class="mx-name">
        <h3 style="display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 0 20px 0 0;">
          <div style="display: flex; justify-content: space-between; align-items: center; width: 110px;">
            <button :class="{'mx-btn-disabled': !prev_btn}" style="font-size: 24px; margin-top: -4px;" @click="prevMonth()" :disabled="!prev_btn">&#8249;</button>
            <span>{{ $t(`mx_calender.month_num.${current_month}`) }}</span>
            <button :class="{'mx-btn-disabled': !next_btn}" style="font-size: 24px; margin-top: -4px;" @click="nextMonth()" :disabled="!next_btn">&#8250;</button>
          </div>
          <span>{{ current_year }}</span>
        </h3>
      </div>
      <div class="mx-col-row">
        <div :class="isMxWeekend(day)" class="mx-col-row__cal" v-for="day in days_array" :key="getMxHeaderDay(day).d ">
          <div class="mx-col-row__cal-header-text">
            <span class="mx-col-row__cal-header-d">{{ getMxHeaderDay(day).d }}</span>
            <span class="mx-col-row__cal-header-w">{{ $t(`mx_calender.week_day_small.${getMxHeaderDay(day).w}`)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "AbwesenheitTableHeader",
  props: {
    days_array: {
      require: true
    },
    current_day: {
      require: true
    }
  },
  data() {
    return {
      total_days: 0,
      current_month: null,
      current_year: 2022,
      prev_btn: true,
      next_btn: true,
      btn_months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    }
  },
  watch: {
    current_month() {
      this.btnDisable()
      this.setMxMonth(this.current_month)
    }
  },
  created() {
    this.current_year = moment().format('YYYY')
    this.current_month = moment().format('MM')
    this.btnDisable()
  },
  methods: {
    btnMontCurrent(btn) {
      return parseInt(btn) === parseInt(moment().format('MM'))
    },
    btnMontActive(btn) {
      return parseInt(btn) === parseInt(this.current_month)
    },
    isMxWeekend(day) {
      if (day.format('dd') === 'Sa' || day.format('dd') === 'Su') {
        return 'tsl-weekend'
      }
      if (this.current_day === day.format('YYYY-MM-DD')) {
        return 'tsl-current-day tsl-current-day-header'
      }
    },
    getMxHeaderDay(day) {
      return {
        w: day.format('dd'), d: day.format('DD')
      }
    },
    setMxYear(year) {
      this.current_year = moment(year).format('YYYY')
      this.$emit('total_days', this.current_year)
    },
    btnDisable() {
      this.prev_btn = parseInt(this.current_month) > 1;
      this.next_btn = parseInt(this.current_month) < 12;
    },
    prevMonth() {
      if (parseInt(this.current_month)  > 1) {
        this.prev_btn = true
        this.current_month = moment(`${this.current_year}-${(parseInt(this.current_month) - 1)}`).format('MM')
      } else {
        this.prev_btn = false
      }
    },
    nextMonth() {
      if (parseInt(this.current_month)  < 12) {
        this.current_month = moment(`${this.current_year}-${(parseInt(this.current_month) + 1)}`).format('MM')
      }
    },
    setMxMonth(month) {
      this.total_days = moment(`${this.current_year}-${month}`).daysInMonth()
      this.current_month = moment(`${this.current_year}-${month}`).format('MM')

      this.$emit('total_days', this.total_days)
      this.$emit('current_month', {current_month: this.current_month, total_days: this.total_days})
    },
  }
}
</script>

<style lang="scss">
.mx-header-btn {
  background: #dce6ec;
  position: relative;
  z-index: 9999999;
  .mx-btn-month {
    background: #dce6ec;
    padding: 8px 20px;
    font-size: 12px;
    &:hover {
      background: #71acbd;
      color: #ffffff;
    }
    &-active {
      background: #2e9dbb !important;
      color: #ffffff !important;
      &:hover {
        background: #2e9dbb;
      }
    }
    &-current {
      background: #a3d2be;
      color: #333;
    }
  }
}
.mx-btn-disabled {
  color: #cecece;
}
</style>
