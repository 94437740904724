<template>
  <div class="mx-wrap">
    <div class="mx-name mx-name-row">
      <span class="mx-name-row__title">{{ person.title }}</span>
      <span class="mx-name-row__subtitle">{{ person.subtitle }}</span>
    </div>
    <div class="mx-col-row">
      <div
          v-for="abd in person.days"
          v-if="isMxCurrentMonth(abd.start) || isMxCurrentMonth(abd.end)"
          class="mx-col-row__block"
          :style="`
          left: ${getDays(abd.start, abd.end, abd.days) * getMxPercent}%;
          width: ${getCountDay(abd.start, abd.end, abd.days) * getMxPercent}%;`"
      >
        <div
            class="mx-col-row__block--content"
            :style="`background: ${getMxStatusColor(abd.status)}`"
        ></div>
      </div>
      <div
          v-for="day in days_array"
          :key="getMxHeaderDay(day).d"
          @click="mxAdd({d: day, p: person})"
          class="mx-col-row__cal"
          :class="isMxWeekend(day)"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AbwesenheitTableRow",
  props: {
    person: {
      require: true
    },
    current_month: {
      require: true
    },
    days_array: {
      require: true
    },
    total_days: {
      require: true
    },
    current_day: {
      require: true
    }
  },
  computed: {
    getMxPercent() {
      switch (this.total_days) {
        case 28: // 28 = 3.57143%
          return 3.57143
        case 30: // 30 = 3.3333%
          return 3.3333
        default: // 31 = 3.22581%
          return 3.22581
      }
    },
  },
  methods: {
    mxAdd(day) {
      console.log(day);
    },
    isMxWeekend(day) {
      if (day.format('dd') === 'Sa' || day.format('dd') === 'Su') {
        return 'tsl-weekend'
      }
      if (this.current_day === day.format('YYYY-MM-DD')) {
        return 'tsl-current-day'
      }
    },
    isMxCurrentMonth(month) {
      return parseInt(moment(month).format('MM')) === parseInt(this.current_month)
    },

    getMxHeaderDay(day) {
      return {
        w: day.format('dd'), d: day.format('DD')
      }
    },
    getMxCalenderDay(day) {
      return day.format('YYYY-MM-DD')
    },

    getMxStatusColor(status) {
      if (status === 'kr') {
        return '#dd4141'
      } else if (status === 'ur') {
        return '#09b66d'
      } else  {
        return '#cecece'
      }
    },
    isCurrentDay() {

    },
    getCountDay(start, end, count) {
      if (this.isMxCurrentMonth(start)) {
        return count
      } else if (this.isMxCurrentMonth(end)) {
        return count + 1
      } else {
        return 0
      }
    },
    getDays(start, end, count) {
      if (this.isMxCurrentMonth(start)) {
        return start.split('-')[2] - 1
      } else if (this.isMxCurrentMonth(end)) {
        return end.split('-')[2] - (count + 1)
      }
    },
  }
}
</script>

<style lang="scss">
.mx-name-row {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  padding: 6px 10px;

  &__title {
    color: #484848;
    font-weight: 500;
    margin-left: 12px;
  }
  &__subtitle {
    color: #999;
    font-size: 12px;
    margin-top: -3px;
    margin-left: 12px;
  }
}
</style>
