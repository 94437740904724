<template>
  <div>
    <AbwesenheitTableHeader
        :current_day="calendar.current_day"
        :days_array="calendar.days_array"
        @current_month="emCurrentMonth"
    />
    <AbwesenheitTableRow
        v-for="person in persons"
        :key="person.id"
        :person="person"
        :current_month="calendar.current_month"
        :days_array="calendar.days_array"
        :current_day="calendar.current_day"
        :total_days="calendar.total_days"
    />
  </div>
</template>

<script>
import moment from "moment";

import AbwesenheitTableHeader from "./AbwesenheitTableHeader";
import AbwesenheitTableRow from "./AbwesenheitTableRow";

export default {
  name: "AbwesenheitTable",
  components: { AbwesenheitTableRow, AbwesenheitTableHeader },
  data() {
    return {
      calendar: {
        total_days: 28,
        current_year: 2022,
        current_month: null,
        current_day: null,
        days_array: [],
      },
      persons: [
        {
          id: 1,
          title: 'Alexander Bechthold',
          subtitle: 'Developer / Administrator',
          days: [
            {start: '2022-04-20', end: '2022-04-22', days: 3, status: 'kr'},
            {start: '2022-04-10', end: '2022-04-10', days: 1, status: 'ur'},
            {start: '2022-03-24', end: '2022-04-04', days: 12, status: 'ur'},
          ]
        },
        {
          id: 2,
          title: 'Ewgenij Bechthold',
          subtitle: 'Car Designer',
          days: [
            {start: '2022-04-13', end: '2022-04-15', days: 3, status: 'ur'},
            {start: '2022-04-01', end: '2022-04-04', days: 4, status: 'ur'},
            {start: '2022-02-07', end: '2022-02-14', days: 8, status: 'ur'},
          ]
        },
        {
          id: 3,
          title: 'Milana Bechthold',
          subtitle: 'Manager / Interior  designer',
          days: [
            {start: '2022-04-11', end: '2022-04-13', days: 3, status: 'ur'},
          ]
        },
        {
          id: 4,
          title: 'Ludmila Bechthold',
          subtitle: 'Accounting manager',
          days: [
            {start: '2022-05-05', end: '2022-05-20', days: 16, status: 'ur'},
          ]
        },



        {
          id: 5,
          title: 'Matthias Müller',
          subtitle: 'Legal assistant',
          days: [
            {start: '2022-10-03', end: '2022-10-07', days: 5, status: 'ur'},
            {start: '2022-10-17', end: '2022-10-19', days: 3, status: 'ur'},
          ]
        },
        {
          id: 6,
          title: 'Sarah Eichel',
          subtitle: 'Chemical equipment tender',
          days: [
            {start: '2022-11-07', end: '2022-11-11', days: 5, status: 'kr'},
          ]
        },
        {
          id: 7,
          title: 'Nicole Loewe',
          subtitle: 'Electrical line worker',
          days: [
            {start: '2022-12-02', end: '2022-12-13', days: 12, status: 'ur'},
          ]
        },
        {
          id: 8,
          title: 'Lucas Schultz',
          subtitle: 'Lead abatement worker',
          days: [
            {start: '2022-04-04', end: '2022-04-07', days: 3, status: 'ur'},
          ]
        },
        {
          id: 9,
          title: 'Anja Schulze',
          subtitle: 'Sales worker driver',
          days: [
            {start: '2022-05-02', end: '2022-05-06', days: 5, status: 'ur'},
          ]
        },
        {
          id: 10,
          title: 'Julia Bieber',
          subtitle: 'Paleontologist',
          days: [
            {start: '2022-05-02', end: '2022-05-05', days: 4, status: 'ur'},
          ]
        },
        {
          id: 11,
          title: 'Tanja Neudorf',
          subtitle: 'Apartment house manager',
          days: [
            {start: '2022-05-23', end: '2022-05-27', days: 5, status: 'ur'},
          ]
        },
        {
          id: 12,
          title: 'Phillipp König',
          subtitle: 'Legal assistant',
          days: []
        },
        {
          id: 13,
          title: 'Dominik Finkel',
          subtitle: 'Chemical equipment tender',
          days: [
            {start: '2022-05-02', end: '2022-05-05', days: 3, status: 'ur'},
          ]
        },
        {
          id: 14,
          title: 'Marcel Meier',
          subtitle: 'Electrical line worker',
          days: [
            {start: '2022-05-16', end: '2022-05-18', days: 3, status: 'ur'},
          ]
        },
        {
          id: 15,
          title: 'Andrea Nussbaum',
          subtitle: 'Lead abatement worker',
          days: [
            {start: '2022-06-02', end: '2022-06-05', days: 5, status: 'ur'},
          ]
        },
        {
          id: 16,
          title: 'Sabine Oster',
          subtitle: 'Sales worker driver',
          days: [
            {start: '2022-07-02', end: '2022-07-05', days: 5, status: 'ur'},
          ]
        },
        {
          id: 17,
          title: 'Jennifer Gottschalk',
          subtitle: 'Paleontologist',
          days: [
            {start: '2022-08-02', end: '2022-08-05', days: 5, status: 'ur'},
          ]
        },
        {
          id: 18,
          title: 'Paul Lang',
          subtitle: 'Apartment house manager',
          days: [
            {start: '2022-09-02', end: '2022-09-05', days: 5, status: 'ur'},
          ]
        }
      ]
    }
  },
  created() {
    this.getMxCurrentYear()
    this.getMxCurrentMonth()
    this.getMxCurrentDay()
    this.getMxCalendar()
  },
  methods: {
    getMxCalendar() {
      this.calendar.days_array = [...Array(this.calendar.total_days)]
      this.calendar.days_array = this.getMxDays()
    },
    getMxCalenderDay(day) {
      return day.format('YYYY-MM-DD')
    },
    getMxCurrentYear() {
      this.calendar.current_year = moment().format('YYYY')
    },
    getMxCurrentMonth() {
      this.calendar.total_days = moment().daysInMonth()
      this.calendar.current_month = moment().format('MM')
    },
    getMxCurrentDay() {
      this.calendar.current_day = moment().format('YYYY-MM-DD')
    },
    getMxDays() {
      moment.updateLocale('', {
        week: {dow: 1} // Начало недели с понедельника (По стандарту начинается неделя с воскресения)
      })

      let start_day = moment(`${this.calendar.current_year}-${this.calendar.current_month}`).startOf('month')
      let end_day = moment(`${this.calendar.current_year}-${this.calendar.current_month}`).endOf('month')
      let day = start_day.subtract(1, 'day').clone()

      return this.calendar.days_array.map(() => day.add(1, 'day').clone())
    },

    emCurrentMonth(element) {
      this.calendar.total_days = element.total_days
      this.calendar.current_month = element.current_month
      this.getMxCalendar()
    }
  }
}
</script>

<style lang="scss">
.mx {
  &-name {
    border: 1px solid #dce6ec;
    border-top: none !important;
    min-height: 50px;
    min-width: 260px;
    max-width: 260px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
    margin-top: -1px;
  }
  &-wrap {
    display: flex;
    background: #ffffff;
    &:first-child {
      border-top: 1px solid #dce6ec;
    }
    &__header {
      background: #fafafa;
      position: sticky;
      width: 100%;
      top: 50px;
      z-index: 99999;
      .mx-col-row__cal {
        min-height: 50px !important;
        border-left: none !important;
        border-right: none !important;
        &:last-child {
          border-right: 1px solid #dce6ec !important;
        }
      }
    }
    .tsl-weekend {
      //background: #fff4e2;
      //color: #ffa90b;

      background: #f4f7f9;
      color: #9ea0a1;
      font-weight: 400;
      .mx-col-row__cal-header {
        &-w, &-d {
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

      }
    }
    .tsl-current-day {
      background: #daf3fa;
      color: #0db5df;
      .mx-col-row__cal-header {
        &-w, &-d {
          color: #0db5df;
        }
      }
      &-header {
        border-bottom: 2px solid #0db5df !important;
        font-weight: 700;
        span:last-child {
          margin-top: -2px;
          margin-bottom: -2px;
        }
      }
    }
    .mx-col {
      &-row {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        &__block {
          top: 0;
          height: 50px;
          position: absolute;
          display: flex;
          align-items: center;
          padding: 0 6px;
          &-count {
            font-size: 12px;
          }
          &--content {
            width: 100%;
            height: 24px;
            cursor: pointer;
            border-radius: 4px;
          }
        }
        &__cal {
          min-height: 40px;
          border: 1px solid #dce6ec;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -1px;
          margin-left: -1px;
          &-header {
            &-text {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              align-items: center;
              font-weight: 500;
            }
            &-w {
              color: rgb(153, 153, 153);
              font-size: 11px;
              margin-top: -2px;
            }
            &-d {
              color: #444444;
            }
          }
        }
      }
    }
  }
}

</style>
