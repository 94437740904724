<template>
  <div style="padding: 0 ;">
    <h2 style="padding: 20px 10px">Abwesenheit</h2>
    <AbwesenheitTable />
  </div>
</template>

<script>
import AbwesenheitTable from "./Components/AbwesenheitTable"

export default {
  name: "AbwesenheitView",
  components: {
    AbwesenheitTable
  }
}
</script>

<style scoped>

</style>
